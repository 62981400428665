import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { Container, Row, Col } from "react-bootstrap";
import GetDagcoins from "../components/get-dagcoins";
import Headertransparent from "../components/headertransparent";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Modalalvar from "../components/modalalvar";
import Modaljuri from "../components/modaljuri";
import Modalmax from "../components/modalmax";
import { Seo } from "../components/seo";

function Sponsorship({ data }) {
  var SeoMeta = data.prismicSponsorships.data;

  var NextUid = data.prismicSponsorships.alternate_languages[0].uid;

  if (data.prismicSponsorships.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicSponsorships.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }
  return (
    <Layout htmllang={data.prismicSponsorships.lang}>
      <Headertransparent
        htmllang={data.prismicSponsorships.lang}
        langswitcher={NextUrl}
      />
      <Hero padding={200} id="sponsorshiphero">
        <div className="herobg sponsorshop-header">
          <GatsbyImage
            alt="Dagcoin"
            image={data.ecobgimg.childImageSharp.gatsbyImageData}
          />
        </div>
        <PrismicRichText
          field={data.prismicSponsorships.data.page_title.richText}
        />
      </Hero>
      <Container className="sponsorshipcontent firstsponsorship">
        <Row>
          <Col>
            <PrismicRichText
              field={data.prismicSponsorships.data.juri_vips.richText}
            />
            <a
              rel="noreferrer"
              href="https://juniorteam.redbull.com/athlete/juri-vips"
              target="_blank"
            >
              {SeoMeta.visit_website_title.text}
            </a>
          </Col>
          <Col>
            <div className="image-with-video-modal">
              <div className="video-play-button-sponsor">
                <span></span>
                <Modaljuri />
              </div>
              <GatsbyImage
                alt="Dagcoin"
                image={data.juri.childImageSharp.gatsbyImageData}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="sponsorshipcontent darkbgsponsor">
        <div className="darksponsorbgcontent">
          {" "}
          <GatsbyImage
            alt="Dagcoin"
            image={data.darkbgsponsor.childImageSharp.gatsbyImageData}
          />
        </div>
        <Row>
          <Col>
            <PrismicRichText
              field={data.prismicSponsorships.data.world_cleanup_day.richText}
            />
            <a
              rel="noreferrer"
              href="https://www.worldcleanupday.org/"
              target="_blank"
            >
              {SeoMeta.visit_website_title.text}
            </a>
          </Col>
          <Col>
            <div className="image-with-video-modal">
              {/* <div className="video-play-button-sponsor">
                <span></span>
                <Modalcleanup/> 
              </div> */}
              <GatsbyImage
                alt="Dagcoin"
                image={data.cleanup.childImageSharp.gatsbyImageData}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="sponsorshipcontent">
        <Row>
          <Col>
            <PrismicRichText
              field={data.prismicSponsorships.data.max_voroski.richText}
            />
            <a
              rel="noreferrer"
              href="https://www.glorykickboxing.com/fighters/max-vorovski:73c9f89f-8de1-4e2b-9db2-8588275be13d"
              target="_blank"
            >
              {SeoMeta.visit_website_title.text}
            </a>
          </Col>
          <Col>
            <div className="image-with-video-modal">
              <div className="video-play-button-sponsor">
                <span></span>
                <Modalmax />
              </div>
              <GatsbyImage
                alt="Dagcoin"
                image={data.max.childImageSharp.gatsbyImageData}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="sponsorshipcontent darkbgsponsor">
        <div className="darksponsorbgcontent">
          {" "}
          <GatsbyImage
            alt="Dagcoin"
            image={data.darkbgsponsor.childImageSharp.gatsbyImageData}
          />
        </div>
        <Row>
          <Col>
            <PrismicRichText
              field={data.prismicSponsorships.data.junior_chamber.richText}
            />
            <a href="https://jci.cc/" target="_blank" rel="noreferrer">
              {SeoMeta.visit_website_title.text}
            </a>
          </Col>
          <Col>
            <div className="image-with-video-modal">
              {/* <div className="video-play-button-sponsor">
                <span></span>
                <Modaljunior/> 
              </div> */}
              <GatsbyImage
                alt="Dagcoin"
                image={data.junior.childImageSharp.gatsbyImageData}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="sponsorshipcontent">
        <Row>
          <Col>
            <PrismicRichText
              field={data.prismicSponsorships.data.alvar.richText}
            />
            <a
              rel="noreferrer"
              href="https://www.instagram.com/alvaralev/?hl=en"
              target="_blank"
            >
              {SeoMeta.visit_website_title.text}
            </a>
          </Col>
          <Col>
            <div className="image-with-video-modal">
              <div className="video-play-button-sponsor">
                <span></span>
                <Modalalvar />
              </div>
              <GatsbyImage
                alt="Dagcoin"
                image={data.alvar.childImageSharp.gatsbyImageData}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const Head = ({ data }) => {
  return (
    <>
      <Seo
        // TODO: Add description
        pathname={data.prismicSponsorships.uid}
        title={data.prismicSponsorships.data.seo_title.text}
        // lang={data.prismicSponsorships.lang}
      />
    </>
  );
};

export const pageQuery = graphql`
  query ($uid: String, $lang: String) {
    prismicSponsorships(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        alvar {
          text
          richText
        }
        junior_chamber {
          text
          richText
        }
        juri_vips {
          text
          richText
        }
        max_voroski {
          text
          richText
        }
        page_title {
          text
          richText
        }
        seo_description {
          text
        }
        seo_title {
          text
        }
        visit_website_title {
          text
          richText
        }
        world_cleanup_day {
          text
          richText
        }
      }
    }
    darkbgsponsor: file(relativePath: { eq: "darkbgsponsor.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    cleanup: file(relativePath: { eq: "cleanup.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
    juri: file(relativePath: { eq: "juri.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, quality: 99, layout: CONSTRAINED)
      }
    }
    max: file(relativePath: { eq: "max.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, quality: 99, layout: CONSTRAINED)
      }
    }
    junior: file(relativePath: { eq: "junior.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, quality: 99, layout: CONSTRAINED)
      }
    }
    alvar: file(relativePath: { eq: "alvar.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, quality: 99, layout: CONSTRAINED)
      }
    }
    ecobgimg: file(relativePath: { eq: "ecosystembackground.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
`;
export default Sponsorship;
