import React from "react";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

class Modal extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div className="open-pop">
        <ModalVideo
          autoplay="1"
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="2WHvYTcz6Iw"
          onClose={() => this.setState({ isOpen: false })}
        />
        <button
          type="button"
          aria-label="openModal"
          onClick={this.openModal}
        ></button>
      </div>
    );
  }
}
export default Modal;
